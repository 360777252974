import React from 'react';
import { useState } from 'react';
import { styled } from '@mui/material/styles';
import { PageContainer, PageContainerToolbar } from '@toolpad/core/PageContainer';
import { AppBar, Toolbar, Box, Select, MenuItem, Slider, Button, Modal, Dialog } from '@mui/material';

import {
  Container,
  List,
  ListItemButton,
  ListItemAvatar,
  ListItemText,
  Avatar,
  SvgIcon
} from '@mui/material';

import WindowCanvas from './components/WindowCanvas';
import AboutModal from './components/AboutModal';

const MenuBar = styled('div')({
  backgroundColor: '#1a1a1a',
  color: 'white',
  padding: '5px',
  justifyContent: 'flex-start',
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
});

function AppListItem({onClick}) {
  return <ListItemButton sx={{ width: '100%' }}
    onClick={() => { onClick() }}>
    <ListItemAvatar>
    </ListItemAvatar>
    <ListItemText
      primary={"React-three-fiber 데모"}
      secondary={"육면체 하나, Leva GUI"}
    />
  </ListItemButton>

}

function App() {
  const [showPaintWindow, setShowPaintWindow] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const windowClose = () => {
    setShowPaintWindow(false);
  };
  const windowOpen = () => {
    setShowPaintWindow(true);
  };

  const modalOpen = () => {
    setShowModal(true);
  }

  return (
    <React.Fragment>
      <AppBar position="fixed" sx={{zIndex: 101}}>
        <MenuBar>
          <MenuItem onClick={()=> modalOpen()}>About</MenuItem>
        </MenuBar>
      </AppBar>
      <Toolbar />
      <Container maxWidth={false} sx={{ width: '100%' }}>
          <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
          <AppListItem onClick={() => windowOpen()}/>
          </List>
      </Container>
      {showPaintWindow && (
        <Dialog fullScreen open={windowOpen}  sx={{zIndex:500}}>
          <WindowCanvas onClose={windowClose} />
        </Dialog>
      )}
      <Modal
        open={showModal}
        onClose={() => setShowModal(false)}
        // aria-labelledby="modal-modal-title"
        // aria-describedby="modal-modal-description"
      >
        <Box>
          <AboutModal/>
        </Box>
      </Modal>

    </React.Fragment>
  );
}


export default App;
