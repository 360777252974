import { Canvas } from "@react-three/fiber";
import { OrbitControls } from '@react-three/drei';
import { styled } from '@mui/material/styles';
import { IconButton, Button } from "@mui/material";
import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';

import { useControls } from 'leva'

const WindowHeader = styled('div')({
  backgroundColor: '#00000000',
  padding: '2px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
})

const R3FCanvas = styled(Canvas)({
  flex: 1,
  position: 'relative',
  backgroundColor: '#f0f0f0',
  width: '100%',
  height: '96%',
})

const WindowCanvas = ({ onClose }) => {
  const { scale, positionX, positionY, positionZ, color, wireframe } =
    useControls('Box', {
      scale: {
        value: 1,
        min: 1,
        max: 3,
        step: 0.1,
      },
      positionX: {
        value: 0,
        min: -3,
        max: 3,
        step: 0.1,
      },
      positionY: {
        value: 0,
        min: -3,
        max: 3,
        step: 0.1,
      },
      positionZ: {
        value: 0,
        min: -3,
        max: 3,
        step: 0.1,
      },
      color: '#f00',
      wireframe: false,
    })

  return (
    <>
      <WindowHeader
        sx={(theme) => ({ zIndex: theme.zIndex.drawer + 1 })}
      >
        <Box>
        <IconButton
          sx={(theme) => ({ color: 'black', backgroundColor: 'white' })}
          aria-label="close" onClick={onClose}>
          <CloseIcon></CloseIcon>
        </IconButton>
        </Box>
      </WindowHeader>
      <R3FCanvas>
        <ambientLight intensity={0.5} />
        <mesh scale={scale}
          position={[positionX, positionY, positionZ]}
        >
          <boxGeometry args={[2, 2, 2]} />
          <meshBasicMaterial color={color} wireframe={wireframe} />
        </mesh>
        <gridHelper />
        <OrbitControls />
      </R3FCanvas>
    </>
  );
}

// const WindowCanvas = ({ onClose }) => {
//   const [{ text }, set] = useControls(() => ({ text: 'my string' }))
//   return <input type="text" value={text} onChange={(e) => set({ text: e.target.value })} />
// }

export default WindowCanvas;